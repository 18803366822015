<template>
  <div class="wrapper">
    <div style="height: 60px; line-height: 60px; font-size: 20px; padding-left: 50px; color: #fff;
      background-color: rgb(38, 52, 69)"><img src="../assets/KOHLER2.png" style="width: 100px; position: relative;">  NFO设维系统 - {{deviceName}}</div>
    <div style="margin: 150px auto; background-color: #fff; width: 350px; height: 350px; padding: 20px; border-radius: 10px">
      <div style="margin: 20px 0; text-align: center; font-size: 24px"><b>登 录</b></div>
      <el-form :model="user" :rules="rules" ref="userForm">
        <el-form-item prop="username">
          <el-input size="medium" prefix-icon="el-icon-user" v-model="user.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="medium" prefix-icon="el-icon-lock" show-password v-model="user.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="rememberMe">记住账号密码</el-checkbox>
        </el-form-item>
        <el-form-item style="margin: 10px 0; text-align: right">
<!--          <el-button type="warning" size="small"  autocomplete="off" @click="$router.push('/register')">注册</el-button>-->
          <el-button type="primary" size="small"  autocomplete="off" @click="login">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {resetRouter, setRoutes} from "@/router";
import Identify from "@/components/Identify";

export default {
  name: "Login",
  data() {
    return {
      user: {
        username: '',
        password: '',
        device: '',
      },
      rememberMe: false,
      code: '',
      dialogFormVisible: false,
      ifmobile: 0,
      deviceName: '电脑端',
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
        ],
      }
    }
  },
  components: {Identify},
  mounted() {
    // 重置路由
    console.log(navigator.userAgent)
    if (this._isMobile()) {
      this.ifmobile = 1;
      this.deviceName = "手机端";
    }
    resetRouter();

    // 从localStorage中获取记住的账号和密码
    const savedUsername = localStorage.getItem('savedUsername');
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedUsername && savedPassword) {
      this.user.username = savedUsername;
      this.user.password = savedPassword;
      this.user.device = navigator.userAgent;
      this.rememberMe = true;
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          this.request.post("/user/login", this.user).then(res => {
            if (res.code === '200') {
              if (this.rememberMe) {
                localStorage.setItem('savedUsername', this.user.username);
                localStorage.setItem('savedPassword', this.user.password);
              } else {
                localStorage.removeItem('savedUsername');
                localStorage.removeItem('savedPassword');
              }
              localStorage.setItem("user", JSON.stringify(res.data));  // 存储用户信息到浏览器
              localStorage.setItem("menus", JSON.stringify(res.data.menus));  // 存储用户信息到浏览器
              localStorage.setItem("ifmobile", this.ifmobile);
              // 动态设置当前用户的路由
              setRoutes();
              if (this.ifmobile === 1) {
                this.$router.push("/mobile")
              } else {
                this.$router.push("/");
              }
              this.$message.success("登录成功");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    handlePass() {
      this.dialogFormVisible = true;
      this.pass = {};
    },
    passwordBack() {
      this.request.put("/user/reset", this.pass).then(res => {
        if (res.code === '200') {
          this.$message.success("重置密码成功，新密码为：123，请尽快修改密码");
          this.dialogFormVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
}
</script>

<style>
.wrapper {
  height: 100vh;
  background-image: linear-gradient(to bottom right, #4169E1, #87CEFA);
  overflow: hidden;
}
</style>
